import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "@radix-ui/themes";
import { StatelyError } from "services/client/errors";
import { errorMessage } from "utils/errors";
import styles from "./ErrorPanel.m.css";

export default function ErrorPanel({
  message,
  eventId,
  error,
}: {
  message?: string;
  eventId?: string;
  error: Error;
}) {
  const rid = error instanceof StatelyError ? error.requestId : undefined;
  eventId ??= error instanceof StatelyError ? error.eventId : undefined;

  return (
    <Card className={styles.error}>
      <h2>
        <FontAwesomeIcon icon={faTriangleExclamation} /> Something went wrong
      </h2>
      <p className={styles.message}>
        {message && <>{message}: </>}
        {errorMessage(error)}
      </p>
      {eventId && <p>Report ID: {eventId}</p>}
      {rid && <p>Request ID: {rid}</p>}
      <a href="https://support.stately.cloud">Contact us for help</a>
    </Card>
  );
}
