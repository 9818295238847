import { faCodeFork, faShapes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Flex, Heading, Text } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { Schema } from "state/user/types";
import TerminalSnippet from "ui/shared/TerminalSnippet";

export function SchemaTile({ schema }: { schema: Schema }) {
  return (
    <Card>
      <Flex direction="column" gap="4">
        <Flex direction="row" justify="between">
          <Flex direction="column" gap="2">
            <Heading size="4" trim="end">
              <FontAwesomeIcon icon={faCodeFork} /> {schema.name}
            </Heading>
            <Text size="1">
              Schema ID: {schema.schemaId.toString()}
              <br />
              Description: {schema.description}
            </Text>
          </Flex>
          <Flex direction="row" gap="4">
            <Link id="schema-link" to={`/schema/${schema.schemaId}`}>
              {/* This button is disabled until we modularize the schema view to not depend on store. */}
              <Button variant="solid" disabled>
                <FontAwesomeIcon icon={faShapes} />
                View Schema
              </Button>
            </Link>
          </Flex>
        </Flex>
        <details>
          <summary>SDK generation instructions</summary>
          <Text size="2">
            To generate a client for this schema, use the following command:
            <TerminalSnippet language="bash">
              {`stately schema generate --schema-id ${schema.schemaId} --version <version> --language <language> <output-dir>`}
            </TerminalSnippet>
          </Text>
        </details>
      </Flex>
    </Card>
  );
}
