// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/delete_user.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/delete_user.proto.
 */
export const file_user_delete_user =
  /*@__PURE__*/
  fileDesc(
    "ChZ1c2VyL2RlbGV0ZV91c2VyLnByb3RvEgxzdGF0ZWx5LnVzZXIiJAoRRGVsZXRlVXNlclJlcXVlc3QSDwoHdXNlcl9pZBgBIAEoBCIUChJEZWxldGVVc2VyUmVzcG9uc2VCdAoQY29tLnN0YXRlbHkudXNlckIPRGVsZXRlVXNlclByb3RvUAGiAgNTVViqAgxTdGF0ZWx5LlVzZXLKAgxTdGF0ZWx5XFVzZXLiAhhTdGF0ZWx5XFVzZXJcR1BCTWV0YWRhdGHqAg1TdGF0ZWx5OjpVc2VyYgZwcm90bzM",
  );

/**
 * Describes the message stately.user.DeleteUserRequest.
 * Use `create(DeleteUserRequestSchema)` to create a new message.
 */
export const DeleteUserRequestSchema = /*@__PURE__*/ messageDesc(file_user_delete_user, 0);

/**
 * Describes the message stately.user.DeleteUserResponse.
 * Use `create(DeleteUserResponseSchema)` to create a new message.
 */
export const DeleteUserResponseSchema = /*@__PURE__*/ messageDesc(file_user_delete_user, 1);
