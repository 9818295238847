// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/delete_store.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file dbmanagement/delete_store.proto.
 */
export const file_dbmanagement_delete_store =
  /*@__PURE__*/
  fileDesc(
    "Ch9kYm1hbmFnZW1lbnQvZGVsZXRlX3N0b3JlLnByb3RvEhRzdGF0ZWx5LmRibWFuYWdlbWVudCImChJEZWxldGVTdG9yZVJlcXVlc3QSEAoIc3RvcmVfaWQYASABKAQiFQoTRGVsZXRlU3RvcmVSZXNwb25zZUKdAQoYY29tLnN0YXRlbHkuZGJtYW5hZ2VtZW50QhBEZWxldGVTdG9yZVByb3RvUAGiAgNTRFiqAhRTdGF0ZWx5LkRibWFuYWdlbWVudMoCFFN0YXRlbHlcRGJtYW5hZ2VtZW504gIgU3RhdGVseVxEYm1hbmFnZW1lbnRcR1BCTWV0YWRhdGHqAhVTdGF0ZWx5OjpEYm1hbmFnZW1lbnRiBnByb3RvMw",
  );

/**
 * Describes the message stately.dbmanagement.DeleteStoreRequest.
 * Use `create(DeleteStoreRequestSchema)` to create a new message.
 */
export const DeleteStoreRequestSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_delete_store, 0);

/**
 * Describes the message stately.dbmanagement.DeleteStoreResponse.
 * Use `create(DeleteStoreResponseSchema)` to create a new message.
 */
export const DeleteStoreResponseSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_delete_store, 1);
