// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/list_all_organizations.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_user_user } from "./user_pb.js";
import { file_user_whoami } from "./whoami_pb.js";

/**
 * Describes the file user/list_all_organizations.proto.
 */
export const file_user_list_all_organizations =
  /*@__PURE__*/
  fileDesc(
    "CiF1c2VyL2xpc3RfYWxsX29yZ2FuaXphdGlvbnMucHJvdG8SDHN0YXRlbHkudXNlciIdChtMaXN0QWxsT3JnYW5pemF0aW9uc1JlcXVlc3QiWwocTGlzdEFsbE9yZ2FuaXphdGlvbnNSZXNwb25zZRI7Cg1vcmdhbml6YXRpb25zGAEgAygLMiQuc3RhdGVseS51c2VyLkxpc3RPcmdhbml6YXRpb25zRW50cnkinAEKFkxpc3RPcmdhbml6YXRpb25zRW50cnkSFwoPb3JnYW5pemF0aW9uX2lkGAEgASgEEgwKBG5hbWUYAiABKAkSLgoHbWVtYmVycxgDIAMoCzIdLnN0YXRlbHkudXNlci5NaW5pbWFsVXNlckluZm8SKwoIcHJvamVjdHMYBCADKAsyGS5zdGF0ZWx5LnVzZXIuUHJvamVjdE5vZGVCfgoQY29tLnN0YXRlbHkudXNlckIZTGlzdEFsbE9yZ2FuaXphdGlvbnNQcm90b1ABogIDU1VYqgIMU3RhdGVseS5Vc2VyygIMU3RhdGVseVxVc2Vy4gIYU3RhdGVseVxVc2VyXEdQQk1ldGFkYXRh6gINU3RhdGVseTo6VXNlcmIGcHJvdG8z",
    [file_user_user, file_user_whoami],
  );

/**
 * Describes the message stately.user.ListAllOrganizationsRequest.
 * Use `create(ListAllOrganizationsRequestSchema)` to create a new message.
 */
export const ListAllOrganizationsRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_list_all_organizations, 0);

/**
 * Describes the message stately.user.ListAllOrganizationsResponse.
 * Use `create(ListAllOrganizationsResponseSchema)` to create a new message.
 */
export const ListAllOrganizationsResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_list_all_organizations, 1);

/**
 * Describes the message stately.user.ListOrganizationsEntry.
 * Use `create(ListOrganizationsEntrySchema)` to create a new message.
 */
export const ListOrganizationsEntrySchema =
  /*@__PURE__*/
  messageDesc(file_user_list_all_organizations, 2);
