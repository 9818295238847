import { Client } from "@connectrpc/connect";
import { Region } from "services/api/dbmanagement/region_pb.js";
import { CallOptions, ClientFactory } from "services/client/web.js";
import { StoreID } from "services/data/data.js";
import { ProjectID } from "services/user/index.js";
import { ManagementService } from "../api/dbmanagement/service_pb.js";
import { StoreConfig } from "../api/dbmanagement/store_info_pb.js";
import { UpdateStoreRequest } from "../api/dbmanagement/update_store_pb.js";
import { StatelyError } from "../client/errors.js";

// re-exports
export type { FieldPath, NamedFieldPathSegment } from "../api/dbmanagement/common_pb.js";
export type {
  GroupConfig,
  GroupVersionConfig,
  SyncFeature,
} from "../api/dbmanagement/config_group_pb.js";
export type {
  GroupLocalIndexConfig,
  ItemTypeConfig,
  KeyConfig,
  TTLConfig,
} from "../api/dbmanagement/config_item_pb.js";
export type {
  BackupConfig,
  CustomTableAccessor,
  RequestRateLimiting,
} from "../api/dbmanagement/config_store_pb.js";
export type { StoreConfig, StoreInfo } from "../api/dbmanagement/store_info_pb.js";
export type {
  StoreUpdate,
  UpdateDescription,
  UpdateName,
  UpdateStoreRequest,
} from "../api/dbmanagement/update_store_pb.js";

/**
 * ManagementClient holds the configuration for talking to the StatelyDB Management API,
 * which is used to read and modify stores in your account. It should be passed
 * to the various service methods exported from this module.
 */
export interface ManagementClient {
  readonly _client: (region: Region) => Client<typeof ManagementService>;
  readonly _defaultClient: Client<typeof ManagementService>;
  readonly callOptions: Readonly<CallOptions>;
}

/**
 * Create a new ManagementClient that holds the configuration for talking to the
 * StatelyDB Management API, which is used to read and modify resources in your
 * account. It should be passed to the various service methods exported from
 * this module.
 * @param client - A Stately Client created by `createClient`.
 * @example
 * const client = createNodeClient({ authTokenProvider });
 * const managementClient = createManagementClient(client);
 * const store = await describeStoreById(managementClient, 12415136);
 */
export function createManagementClient(
  client: (region: Region) => ClientFactory,
): ManagementClient {
  return {
    _client: (region) => client(region)(ManagementService),
    _defaultClient: client(Region.AWS_US_WEST_2)(ManagementService),
    callOptions: {
      // Default to 10s timeout
      timeoutMs: 10_000,
    },
  };
}

/**
 * CreateStore makes a new store within your project. It will fail if the
 * store already exists or you don't have permission to create stores in that
 * project.
 * @param client - A {@linkcode ManagementClient} created by {@linkcode createManagementClient}.
 * @param projectId - The ID of the project to create the store in.
 * @param name - The human-readable name of the store.
 * @param description - A description of the store.
 */
export async function createStore(
  client: ManagementClient,
  region: Region,
  projectId: ProjectID,
  name: string,
  description: string,
): Promise<{ storeId: StoreID }> {
  return handleErrors(
    client._client(region).createStore({ projectId, name, description }, client.callOptions),
  );
}

/**
 * DeleteStore schedules a store to be deleted, including all data within it.
 * This operation takes some time so it returns a handle to an operation that
 * you can check to see if it is complete. This will fail if the store does
 * not exist, if the store is already being deleted, or if you do not have
 * permission to delete stores.
 * @param client - A {@linkcode ManagementClient} created by {@linkcode createManagementClient}.
 * @param storeId - The ID of the store to delete.
 * @param region - The region to delete the store from - this should be the region the store is in!
 */
export async function deleteStore(client: ManagementClient, region: Region, storeId: StoreID) {
  await handleErrors(client._client(region).deleteStore({ storeId }, client.callOptions));
}

/**
 * DescribeStore gets information about a store. This includes Store-level configuration
 * and features, Per-group configuration, and any item configurations interpreted from schema.
 * In the future we may decide to also include schema information here, but for now
 * that is a separate API call.
 * This API will fail if the store does not exist or you don't have permission
 * to describe stores in this project.
 * @param client - A {@linkcode ManagementClient} created by {@linkcode createManagementClient}.
 * @param storeId - The ID of the store to delete.
 */
export async function describeStore(
  client: ManagementClient,
  storeId: StoreID,
): Promise<StoreConfig> {
  return handleErrors(
    client._defaultClient.describeStore({ storeId }, client.callOptions).then((r) => r.store!),
  );
}

/**
 * UpdateStore allows you to change the configuration of a store and groups within it.
 * This will fail if the store does not exist, or you do not have permission to update it.
 * @param client - A {@linkcode ManagementClient} created by {@linkcode createManagementClient}.
 * @param request - The fully-formed store update request.
 * @param region - The region to update the store from - this should be the region the store is in!
 */
export async function updateStore(
  client: ManagementClient,
  region: Region,
  request: UpdateStoreRequest,
): Promise<StoreConfig> {
  return handleErrors(
    client
      ._client(region)
      .updateStore(request, client.callOptions)
      .then((r) => r.store!),
  );
}

async function handleErrors<T>(promise: Promise<T>): Promise<T> {
  try {
    return await promise;
  } catch (e) {
    throw StatelyError.from(e);
  }
}
