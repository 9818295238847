// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/schema_info.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file dbmanagement/schema_info.proto.
 */
export const file_dbmanagement_schema_info =
  /*@__PURE__*/
  fileDesc(
    "Ch5kYm1hbmFnZW1lbnQvc2NoZW1hX2luZm8ucHJvdG8SFHN0YXRlbHkuZGJtYW5hZ2VtZW50IkIKClNjaGVtYUluZm8SEQoJc2NoZW1hX2lkGAEgASgEEgwKBG5hbWUYAyABKAkSEwoLZGVzY3JpcHRpb24YBCABKAlCnAEKGGNvbS5zdGF0ZWx5LmRibWFuYWdlbWVudEIPU2NoZW1hSW5mb1Byb3RvUAGiAgNTRFiqAhRTdGF0ZWx5LkRibWFuYWdlbWVudMoCFFN0YXRlbHlcRGJtYW5hZ2VtZW504gIgU3RhdGVseVxEYm1hbmFnZW1lbnRcR1BCTWV0YWRhdGHqAhVTdGF0ZWx5OjpEYm1hbmFnZW1lbnRiBnByb3RvMw",
  );

/**
 * Describes the message stately.dbmanagement.SchemaInfo.
 * Use `create(SchemaInfoSchema)` to create a new message.
 */
export const SchemaInfoSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_schema_info, 0);
