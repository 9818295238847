// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/validate.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_descriptor } from "@bufbuild/protobuf/wkt";

/**
 * Describes the file schemaservice/validate.proto.
 */
export const file_schemaservice_validate =
  /*@__PURE__*/
  fileDesc(
    "ChxzY2hlbWFzZXJ2aWNlL3ZhbGlkYXRlLnByb3RvEhVzdGF0ZWx5LnNjaGVtYXNlcnZpY2UiUAoPVmFsaWRhdGVSZXF1ZXN0Ej0KD2ZpbGVfZGVzY3JpcHRvchgBIAEoCzIkLmdvb2dsZS5wcm90b2J1Zi5GaWxlRGVzY3JpcHRvclByb3RvImQKEFZhbGlkYXRlUmVzcG9uc2USGAoQZm9ybWF0dGVkX3NjaGVtYRgBIAEoCRI2CgZlcnJvcnMYAiADKAsyJi5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuVmFsaWRhdGlvbkVycm9yIkoKD1ZhbGlkYXRpb25FcnJvchIPCgdtZXNzYWdlGAEgASgJEhEKCWl0ZW1fdHlwZRgCIAEoCRITCgtpc19pbnRlcm5hbBgDIAEoCEKfAQoZY29tLnN0YXRlbHkuc2NoZW1hc2VydmljZUINVmFsaWRhdGVQcm90b1ABogIDU1NYqgIVU3RhdGVseS5TY2hlbWFzZXJ2aWNlygIVU3RhdGVseVxTY2hlbWFzZXJ2aWNl4gIhU3RhdGVseVxTY2hlbWFzZXJ2aWNlXEdQQk1ldGFkYXRh6gIWU3RhdGVseTo6U2NoZW1hc2VydmljZWIGcHJvdG8z",
    [file_google_protobuf_descriptor],
  );

/**
 * Describes the message stately.schemaservice.ValidateRequest.
 * Use `create(ValidateRequestSchema)` to create a new message.
 */
export const ValidateRequestSchema = /*@__PURE__*/ messageDesc(file_schemaservice_validate, 0);

/**
 * Describes the message stately.schemaservice.ValidateResponse.
 * Use `create(ValidateResponseSchema)` to create a new message.
 */
export const ValidateResponseSchema = /*@__PURE__*/ messageDesc(file_schemaservice_validate, 1);

/**
 * Describes the message stately.schemaservice.ValidationError.
 * Use `create(ValidationErrorSchema)` to create a new message.
 */
export const ValidationErrorSchema = /*@__PURE__*/ messageDesc(file_schemaservice_validate, 2);
