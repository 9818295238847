import { useAuth0 } from "@auth0/auth0-react";
import { Card, Flex, Text } from "@radix-ui/themes";
import { useEffect } from "react";
import Logo from "ui/logo/Logo";
import LoginButton from "ui/menu/LoginButton";
import styles from "./UnauthenticatedHome.m.css";

export default function UnauthenticatedHome() {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    if (!window.location.search.includes("noRedirectToLogin=true")) {
      // Immediately redirect to the login page
      loginWithRedirect();
    }
  });

  return (
    <div className={styles.container}>
      <Card size="3" style={{ maxWidth: 480 }}>
        <Flex gap="4" align="center" direction="column">
          <Logo className={styles.logo} text alt="Stately Cloud Logo" />
          <Text as="p" size="3">
            We are redirecting you to a login page. If you are not redirected, click the button
            below.
          </Text>
          <LoginButton />
        </Flex>
      </Card>
    </div>
  );
}
