import { replayIntegration } from "@sentry/browser";
import {
  BrowserOptions,
  captureException,
  init,
  reactRouterV6BrowserTracingIntegration,
  setTag,
  withScope,
} from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import { StatelyError } from "services/client/errors";

const options: BrowserOptions = {
  enabled: $featureFlags.sentry,
  dsn: "https://b09401a9ed23f79d9d7f29b0b5b5ffc5@o4506147541417984.ingest.sentry.io/4506147643457536",
  // TODO: in the future, we can adjust these sample rates by stage.
  tracesSampleRate: 1.0,
  sampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: $VERSION,
  environment: $FLAVOR,
  ignoreErrors: [],
  attachStacktrace: true,
  // Only send trace headers to our own server
  tracePropagationTargets: [$BACKEND_ENDPOINT],
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    replayIntegration(),
  ],
};

init(options);

/**
 * Sentry.io error reporting. When Sentry isn't enabled this is just a log.
 *
 * @param tag an informative label for categorizing this log
 */
export const reportException = (tag: string, e: any, errorInfo?: Record<string, unknown>) => {
  let eventId: string | undefined;
  withScope((scope) => {
    setTag("context", tag);
    if (errorInfo) {
      scope.setExtras(errorInfo);
    }
    eventId = captureException(e);
  });

  if (e instanceof StatelyError) {
    e.eventId = eventId;
  }

  return eventId;
};
