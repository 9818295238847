// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/delete_project.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/delete_project.proto.
 */
export const file_user_delete_project =
  /*@__PURE__*/
  fileDesc(
    "Chl1c2VyL2RlbGV0ZV9wcm9qZWN0LnByb3RvEgxzdGF0ZWx5LnVzZXIiKgoURGVsZXRlUHJvamVjdFJlcXVlc3QSEgoKcHJvamVjdF9pZBgBIAEoBCIXChVEZWxldGVQcm9qZWN0UmVzcG9uc2VCdwoQY29tLnN0YXRlbHkudXNlckISRGVsZXRlUHJvamVjdFByb3RvUAGiAgNTVViqAgxTdGF0ZWx5LlVzZXLKAgxTdGF0ZWx5XFVzZXLiAhhTdGF0ZWx5XFVzZXJcR1BCTWV0YWRhdGHqAg1TdGF0ZWx5OjpVc2VyYgZwcm90bzM",
  );

/**
 * Describes the message stately.user.DeleteProjectRequest.
 * Use `create(DeleteProjectRequestSchema)` to create a new message.
 */
export const DeleteProjectRequestSchema = /*@__PURE__*/ messageDesc(file_user_delete_project, 0);

/**
 * Describes the message stately.user.DeleteProjectResponse.
 * Use `create(DeleteProjectResponseSchema)` to create a new message.
 */
export const DeleteProjectResponseSchema = /*@__PURE__*/ messageDesc(file_user_delete_project, 1);
