// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/update_organization.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_dbmanagement_update_store } from "../dbmanagement/update_store_pb.js";
import { file_user_organization } from "./organization_pb.js";

/**
 * Describes the file user/update_organization.proto.
 */
export const file_user_update_organization =
  /*@__PURE__*/
  fileDesc(
    "Ch51c2VyL3VwZGF0ZV9vcmdhbml6YXRpb24ucHJvdG8SDHN0YXRlbHkudXNlciJeChlVcGRhdGVPcmdhbml6YXRpb25SZXF1ZXN0EhcKD29yZ2FuaXphdGlvbl9pZBgBIAEoBBIoCgd1cGRhdGVzGAIgAygLMhcuc3RhdGVseS51c2VyLk9yZ1VwZGF0ZSJFChpVcGRhdGVPcmdhbml6YXRpb25SZXNwb25zZRInCgNvcmcYASABKAsyGi5zdGF0ZWx5LnVzZXIuT3JnYW5pemF0aW9uIk4KCU9yZ1VwZGF0ZRI3Cgt1cGRhdGVfbmFtZRgBIAEoCzIgLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlVwZGF0ZU5hbWVIAEIICgZ1cGRhdGVCfAoQY29tLnN0YXRlbHkudXNlckIXVXBkYXRlT3JnYW5pemF0aW9uUHJvdG9QAaICA1NVWKoCDFN0YXRlbHkuVXNlcsoCDFN0YXRlbHlcVXNlcuICGFN0YXRlbHlcVXNlclxHUEJNZXRhZGF0YeoCDVN0YXRlbHk6OlVzZXJiBnByb3RvMw",
    [file_dbmanagement_update_store, file_user_organization],
  );

/**
 * Describes the message stately.user.UpdateOrganizationRequest.
 * Use `create(UpdateOrganizationRequestSchema)` to create a new message.
 */
export const UpdateOrganizationRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_update_organization, 0);

/**
 * Describes the message stately.user.UpdateOrganizationResponse.
 * Use `create(UpdateOrganizationResponseSchema)` to create a new message.
 */
export const UpdateOrganizationResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_update_organization, 1);

/**
 * Describes the message stately.user.OrgUpdate.
 * Use `create(OrgUpdateSchema)` to create a new message.
 */
export const OrgUpdateSchema = /*@__PURE__*/ messageDesc(file_user_update_organization, 2);
