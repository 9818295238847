// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/create_project.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/create_project.proto.
 */
export const file_user_create_project =
  /*@__PURE__*/
  fileDesc(
    "Chl1c2VyL2NyZWF0ZV9wcm9qZWN0LnByb3RvEgxzdGF0ZWx5LnVzZXIiaQoUQ3JlYXRlUHJvamVjdFJlcXVlc3QSFwoPb3JnYW5pemF0aW9uX2lkGAEgASgEEgwKBG5hbWUYAiABKAkSEwoLZGVzY3JpcHRpb24YAyABKAkSFQoNY3JlYXRlX3NjaGVtYRgEIAEoCCIrChVDcmVhdGVQcm9qZWN0UmVzcG9uc2USEgoKcHJvamVjdF9pZBgBIAEoBEJ3ChBjb20uc3RhdGVseS51c2VyQhJDcmVhdGVQcm9qZWN0UHJvdG9QAaICA1NVWKoCDFN0YXRlbHkuVXNlcsoCDFN0YXRlbHlcVXNlcuICGFN0YXRlbHlcVXNlclxHUEJNZXRhZGF0YeoCDVN0YXRlbHk6OlVzZXJiBnByb3RvMw",
  );

/**
 * Describes the message stately.user.CreateProjectRequest.
 * Use `create(CreateProjectRequestSchema)` to create a new message.
 */
export const CreateProjectRequestSchema = /*@__PURE__*/ messageDesc(file_user_create_project, 0);

/**
 * Describes the message stately.user.CreateProjectResponse.
 * Use `create(CreateProjectResponseSchema)` to create a new message.
 */
export const CreateProjectResponseSchema = /*@__PURE__*/ messageDesc(file_user_create_project, 1);
