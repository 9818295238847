// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/put.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_descriptor } from "@bufbuild/protobuf/wkt";
import { file_migration } from "../migration_pb.js";
import { file_schemaservice_validate } from "./validate_pb.js";

/**
 * Describes the file schemaservice/put.proto.
 */
export const file_schemaservice_put =
  /*@__PURE__*/
  fileDesc(
    "ChdzY2hlbWFzZXJ2aWNlL3B1dC5wcm90bxIVc3RhdGVseS5zY2hlbWFzZXJ2aWNlIusBCgpQdXRSZXF1ZXN0EhEKCXNjaGVtYV9pZBgGIAEoBBI9Cg9maWxlX2Rlc2NyaXB0b3IYAiABKAsyJC5nb29nbGUucHJvdG9idWYuRmlsZURlc2NyaXB0b3JQcm90bxIPCgdkcnlfcnVuGAMgASgIEhoKEmNoYW5nZV9kZXNjcmlwdGlvbhgEIAEoCRIkChxhbGxvd19iYWNrd2FyZHNfaW5jb21wYXRpYmxlGAUgASgIEjIKCm1pZ3JhdGlvbnMYByADKAsyHi5zdGF0ZWx5LnNjaGVtYW1vZGVsLk1pZ3JhdGlvbkoECAEQAiJ/CgtQdXRSZXNwb25zZRJCChF2YWxpZGF0ZV9yZXNwb25zZRgBIAEoCzInLnN0YXRlbHkuc2NoZW1hc2VydmljZS5WYWxpZGF0ZVJlc3BvbnNlEhEKCWNvbW1pdHRlZBgCIAEoCBIZChFzY2hlbWFfdmVyc2lvbl9pZBgDIAEoDUKaAQoZY29tLnN0YXRlbHkuc2NoZW1hc2VydmljZUIIUHV0UHJvdG9QAaICA1NTWKoCFVN0YXRlbHkuU2NoZW1hc2VydmljZcoCFVN0YXRlbHlcU2NoZW1hc2VydmljZeICIVN0YXRlbHlcU2NoZW1hc2VydmljZVxHUEJNZXRhZGF0YeoCFlN0YXRlbHk6OlNjaGVtYXNlcnZpY2ViBnByb3RvMw",
    [file_google_protobuf_descriptor, file_migration, file_schemaservice_validate],
  );

/**
 * Describes the message stately.schemaservice.PutRequest.
 * Use `create(PutRequestSchema)` to create a new message.
 */
export const PutRequestSchema = /*@__PURE__*/ messageDesc(file_schemaservice_put, 0);

/**
 * Describes the message stately.schemaservice.PutResponse.
 * Use `create(PutResponseSchema)` to create a new message.
 */
export const PutResponseSchema = /*@__PURE__*/ messageDesc(file_schemaservice_put, 1);
