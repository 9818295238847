import { memoize } from "es-toolkit";
import { useMemo } from "react";
import { Region } from "services/api/dbmanagement/region_pb.js";
import { regionToString } from "utils/region";
import { useAuthToken } from "../auth/useAuthToken";
import { ClientFactory, createWebClient } from "./web";

export function useStatelyClient(): (region: Region) => ClientFactory {
  const getToken = useAuthToken();
  return useMemo(
    () =>
      memoize((region) =>
        createWebClient({
          endpoint:
            $BACKEND_ENDPOINT === `https://api.stately.cloud` && region !== Region.AWS_US_WEST_2
              ? `https://${regionToString(region)}.aws.api.stately.cloud`
              : $BACKEND_ENDPOINT,
          authTokenProvider: getToken,
          noAdmin: localStorage.getItem("noAdmin") === "true",
        }),
      ),
    [getToken],
  );
}
