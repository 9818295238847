import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Callout, Flex, Heading } from "@radix-ui/themes";
import { useHasStores, useOrganizations } from "state/user/hooks";
import { OrganizationSection } from "ui/organization/OrganizationSection";
import WelcomeCard from "./WelcomeCard";

export default function AuthenticatedHome() {
  const organizations = useOrganizations();
  const hasStores = useHasStores();

  // TODO: style as a menu of selection options with org headers.

  return (
    <Flex gap="4" direction="column" mt="4">
      <Heading size="7" trim="end">
        Your StatelyDB Resources
      </Heading>

      {!hasStores ? (
        <WelcomeCard />
      ) : (
        <>
          {organizations.map((org) => (
            <OrganizationSection org={org} key={org.organizationId.toString()} />
          ))}

          {organizations.length < 2 && (
            <Callout.Root size="1" variant="outline" color="gray">
              <Callout.Icon>
                <FontAwesomeIcon icon={faCircleInfo} />
              </Callout.Icon>
              <Callout.Text>
                An organization was created for you when you signed up. If you need more
                organizations or you want to add more users to an existing organization contact{" "}
                <a href="mailto:support@stately.cloud">support@stately.cloud</a>.
              </Callout.Text>
            </Callout.Root>
          )}
        </>
      )}
    </Flex>
  );
}
