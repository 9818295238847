import { Button, Flex, Heading, Tooltip } from "@radix-ui/themes";
import { useOrganizations } from "state/user/hooks";
import ProfileAvatar from "ui/menu/ProfileAvatar";
import AdminOnly from "ui/shared/AdminOnly";

export default function UserAccount() {
  const organizations = useOrganizations();
  return (
    <Flex gap="4" direction="column" mt="4">
      <Heading size="7" trim="end">
        Your Account
      </Heading>
      <ProfileAvatar />
      <div>
        <Tooltip
          delayDuration={0}
          content="Contact support@stately.cloud for help with your Account settings."
        >
          <Button disabled>Edit Account</Button>
        </Tooltip>
      </div>
      <Heading size="4" trim="end">
        Organizations
      </Heading>
      <p>You are a member of the following organizations:</p>
      <ul>
        {organizations.map((org) => (
          <li key={org.organizationId.toString()}>{org.name}</li>
        ))}
      </ul>

      <AdminOnly>
        <Button variant="solid" disabled>
          Delete Account
        </Button>
      </AdminOnly>
    </Flex>
  );
}
