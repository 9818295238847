import { Flex, Heading, Link, Text } from "@radix-ui/themes";

export default function SupportPage() {
  return (
    <Flex gap="4" direction="column" mt="4">
      <Heading>Contact Us</Heading>
      <Text>
        You can reach out at any time, for any reason at{" "}
        <Link href="mailto:support@stately.cloud">support@stately.cloud</Link> and we'll get back to
        you shortly.
      </Text>
    </Flex>
  );
}
