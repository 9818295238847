// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/create_access_key.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/create_access_key.proto.
 */
export const file_user_create_access_key =
  /*@__PURE__*/
  fileDesc(
    "Chx1c2VyL2NyZWF0ZV9hY2Nlc3Nfa2V5LnByb3RvEgxzdGF0ZWx5LnVzZXIiMQoWQ3JlYXRlQWNjZXNzS2V5UmVxdWVzdBIXCg9vcmdhbml6YXRpb25faWQYASABKAQiRAoXQ3JlYXRlQWNjZXNzS2V5UmVzcG9uc2USFQoNYWNjZXNzX2tleV9pZBgBIAEoBBISCgphY2Nlc3Nfa2V5GAIgASgJQnkKEGNvbS5zdGF0ZWx5LnVzZXJCFENyZWF0ZUFjY2Vzc0tleVByb3RvUAGiAgNTVViqAgxTdGF0ZWx5LlVzZXLKAgxTdGF0ZWx5XFVzZXLiAhhTdGF0ZWx5XFVzZXJcR1BCTWV0YWRhdGHqAg1TdGF0ZWx5OjpVc2VyYgZwcm90bzM",
  );

/**
 * Describes the message stately.user.CreateAccessKeyRequest.
 * Use `create(CreateAccessKeyRequestSchema)` to create a new message.
 */
export const CreateAccessKeyRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_create_access_key, 0);

/**
 * Describes the message stately.user.CreateAccessKeyResponse.
 * Use `create(CreateAccessKeyResponseSchema)` to create a new message.
 */
export const CreateAccessKeyResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_create_access_key, 1);
