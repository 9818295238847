import { FileDescriptorProto } from "@bufbuild/protobuf/wkt";
import { Client } from "@connectrpc/connect";
import { CallOptions, ClientFactory } from "services/client/web.js";
import { SchemaID } from "services/data/data.js";
import { SchemaAuditLogEntry } from "../api/schemaservice/list_audit_log_pb.js";
import { SchemaModel as Schema } from "../api/schemaservice/schema_pb.js";
import { SchemaService } from "../api/schemaservice/service_pb.js";
import { StatelyError } from "../client/errors.js";

// re-exports
export type { SchemaAuditLogEntry } from "../api/schemaservice/list_audit_log_pb.js";
export type { SchemaModel as Schema } from "../api/schemaservice/schema_pb.js";
/**
 * SchemaClient holds the configuration for talking to the StatelyDB Schema API,
 * which is used to read and modify schemas for your stores. It should be passed
 * to the various service methods exported from this module.
 */

export interface SchemaClient {
  readonly _client: Client<typeof SchemaService>;
  readonly callOptions: Readonly<CallOptions>;
}

/**
 * Create a new SchemaClient that holds the configuration for talking to the
 * StatelyDB Schema API, which is used to read and modify schemas for your stores.
 * It should be passed to the various service methods exported from this
 * module.
 * @param client - A Stately Client created by `createClient`.
 * @param storeId - The store ID to use for this client - all calls this client is passed into will be targeted to this store.
 * @example
 * const client = createNodeClient({ authTokenProvider });
 * const schemaClient = createSchemaClient(client, 1221515n);
 * const schema = await get(schemaClient);
 */
export function createSchemaClient(client: ClientFactory): SchemaClient {
  return {
    _client: client(SchemaService),
    callOptions: {
      // Default to 10s timeout
      timeoutMs: 10_000,
    },
  };
}

/**
 * Put adds a Schema to the StatelyDB Schema Store or replaces the Schema if
 * it already exists. If the caller attempts to put a Schema for a Store that
 * does not exist the request will fail. If the caller does not have
 * permissions to access the Store the request will fail. If the Schema is not
 * valid the request will fail. If a Schema already exists for the Store then
 * the update will only be accepted if the new Schema is backwards compatible
 * with the existing Schema.
 * @param client - A {@linkcode SchemaClient} created by {@linkcode createSchemaClient}.
 * @param fileDescriptor - A protobuf {@linkcode FileDescriptorProto} which describes the Store.
 * @param dryRun - A flag that indicates whether the request should be executed as a dry run.
 * @param changeDescription - A human-readable description of this Schema change and the reason for making it.
 * @param allowBackwardsIncompatible - A flag that indicates a backwards incompatible Schema change is allowed.
 *  Setting this flag can result in loss of data.
 * @example
 * const client = createNodeClient({ authTokenProvider });
 * const schemaClient = createSchemaClient(client, 1221515n);
 * await put(newDescriptor, "Update the schema");
 */
export async function put(
  client: SchemaClient,
  schemaId: SchemaID,
  fileDescriptor: FileDescriptorProto,
  dryRun: boolean,
  changeDescription: string,
  allowBackwardsIncompatible: boolean,
): Promise<void> {
  await handleErrors(
    client._client.put(
      {
        schemaId,
        fileDescriptor,
        dryRun,
        allowBackwardsIncompatible,
        changeDescription,
      },
      client.callOptions,
    ),
  );
}

/**
 * Get retrieves the fully self-contained Schema for the corresponding Store
 * ID. There is only one Schema per Store so the result of this call will
 * contain the most up-to-date representation of the Items in the Store. It
 * will fail if the caller does not have permission the Store.
 * If the store does not have a schema yet, this will return undefined.
 * @param client - A {@linkcode SchemaClient} created by {@linkcode createSchemaClient}.
 * @example
 * const client = createNodeClient({ authTokenProvider });
 * const schemaClient = createSchemaClient(client, 1221515n);
 * const schema = await get(schemaClient);
 */
export async function get(client: SchemaClient, schemaId: SchemaID): Promise<Schema | undefined> {
  const resp = await handleErrors(
    client._client.get(
      {
        schemaId,
        // TODO - implement passing in versions
      },
      client.callOptions,
    ),
  );
  return resp.schema;
}

/**
 * ListAuditLog retrieves the audit log for the Schema associated with the provided storeId.
 * The audit log consists of a list of audit log entries that represent each change to the Schema including
 * its creation. The list is ordered by the time of the change with the most recent change first.
 * If there is no Schema for the provided Store ID, an empty list will be returned.
 * @param client - A {@linkcode SchemaClient} created by {@linkcode createSchemaClient}.
 * @param options.limit - The maximum number of paths to return. Defaults to a reasonable number.
 * @example
 * const client = createNodeClient({ authTokenProvider });
 * const schemaClient = createSchemaClient(client, 1221515n);
 * const logEntries = await listAuditLog(schemaClient, { limit: 15 });
 */
export async function listAuditLog(
  client: SchemaClient,
  schemaId: SchemaID,
  { limit = 10 }: { limit?: number } = {},
): Promise<SchemaAuditLogEntry[]> {
  const resp = await handleErrors(
    client._client.listAuditLog(
      {
        schemaId,
        limit,
      },
      client.callOptions,
    ),
  );
  return resp.entries;
}

export async function createSchema(
  client: SchemaClient,
  projectId: bigint,
  name: string,
  description: string,
): Promise<bigint | undefined> {
  const resp = await handleErrors(
    client._client.create(
      {
        projectId,
        name,
        description,
      },
      client.callOptions,
    ),
  );
  return resp.schemaId;
}

async function handleErrors<T>(promise: Promise<T>): Promise<T> {
  try {
    return await promise;
  } catch (e) {
    throw StatelyError.from(e);
  }
}
