import { DescService } from "@bufbuild/protobuf";
import { Client, createClient, type Interceptor } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { AuthTokenProvider, createAuthMiddleware } from "./auth.js";
import { grpcWebDevtoolsInterceptor } from "./devtools.js";
import { jsonResponseMiddleware } from "./json-response.js";
import { noAdminMiddleware } from "./noadmin.js";
import { requestIdMiddleware } from "./request-id.js";

export type ClientFactory = <Service extends DescService>(definition: Service) => Client<Service>;

/**
 * All of the per-call client options that could be set. We'll only expose some
 * of these.
 * @private
 */
export interface CallOptions {
  /**
   * Timeout in milliseconds. Each call made from this client will be canceled
   * `timeoutMs` milliseconds after it starts. If cancelled, the call will throw
   * an StatelyError with the Status.Canceled status.
   *
   * Set to <= 0 to disable the default timeout.
   */
  timeoutMs?: number;

  /**
   * Absolute deadline for calls. If set, all calls made from this client will
   * be canceled at this time. This is useful for passing along an overall
   * deadline for a request through multiple database calls. If cancelled, the
   * call will throw an StatelyError with the Status.Canceled status.
   */
  deadline?: Date;

  /**
   * An optional AbortSignal to cancel the call. If cancelled, the call will
   * throw an StatelyError with the Status.Canceled status.
   */
  signal?: AbortSignal;

  /**
   * If true, you're okay with getting a slightly stale item - that is, if you
   * had just changed an item and then call get or list on it, you might get the
   * old version of the item. This can result in improved performance,
   * availability, and cost. This affects get and list operations.
   */
  allowStale?: boolean;
}

export interface ClientOptions {
  /**
   * The Stately Cloud API endpoint to use. If not set, this will use the
   * default endpoint.
   */
  endpoint?: string;

  /**
   * An async function that returns the auth token to use for requests. We
   * provide some common implementations of this, but you may want to provide
   * your own.
   */
  authTokenProvider?: AuthTokenProvider;
}

/**
 * Creates a configuration for a Stately Cloud API client to be used from web
 * browsers and other environments that use the "fetch" API. This allows us to
 * share connections and structures between various API clients - it gets passed
 * into functions that construct clients for individual APIs.
 *
 * Note: This client cannot use the `transaction` API, as it requires a
 * full-duplex HTTP client library which is only available with the NodeJS
 * client.
 */
export function createWebClient(
  opts: Partial<ClientOptions & { noAdmin?: boolean }> = {},
): ClientFactory {
  if (!("fetch" in globalThis)) {
    throw new Error("createWebClient can only be used in environments with `fetch` available");
  }

  const { authTokenProvider, endpoint = "https://api.stately.cloud" } = opts;
  if (!authTokenProvider) {
    throw new Error("authTokenProvider is required for now");
  }

  // TODO: We're installing all the middlewares here by default, but in the
  // future we could expose a custom client builder that only installs (and pays
  // for in bundle size) the middlewares a user wants.

  const interceptors: Interceptor[] = [
    requestIdMiddleware,
    jsonResponseMiddleware,
    createAuthMiddleware(authTokenProvider),
    // retryMiddleware,
    // Ideally we want a per-try timeout and an overall deadline
  ];

  if (opts.noAdmin === true) {
    interceptors.push(noAdminMiddleware);
  }

  // https://github.com/SafetyCulture/grpc-web-devtools
  if ("window" in globalThis && "__CONNECT_WEB_DEVTOOLS__" in globalThis.window) {
    interceptors.push(grpcWebDevtoolsInterceptor);
  }

  const transport = createConnectTransport({
    baseUrl: endpoint,
    interceptors,
  });

  return (definition) => createClient(definition, transport);
}
