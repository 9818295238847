import Lottie from "lottie-react";
import styles from "./LoadingOverlay.m.css";
import loadingAnimation from "./stately-loader.json";

/**
 * LoadingOverlay is a really simple component to show a loading spinner over the top of the parent
 * element while the loading prop is set to `true`.
 *
 * You need to make sure this is the last element in the container or else it won't show on top!
 *
 * @example
 * <LoadingOverlay>Loading data...</LoadingOverlay>
 */
export function LoadingOverlay({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.overlay}>
      <Lottie animationData={loadingAnimation} />
      <div>{children}</div>
    </div>
  );
}
