import { Box, Card, Flex, Heading, Inset, Link, Strong, Text } from "@radix-ui/themes";
import styles from "./WelcomeCard.m.css";

export default function WelcomeCard() {
  return (
    <Box>
      <Card size="2">
        <Inset clip="padding-box" side="top" pb="current" className={styles.welcomeImage} />
        <Flex gap="4" direction="column" p="3" mt="4">
          <Heading>Welcome Aboard!</Heading>
          <Text as="p" size="3">
            <Strong>You're almost ready to start using StatelyDB.</Strong> You have an account, but
            you do not have any StatelyDB Stores created that you can interact with. To get started,
            email <Link href="mailto:support@stately.cloud">support@stately.cloud</Link> and tell us
            a little bit about your use case. If you're just checking us out and exploring, that's
            totally fine! We will get a Store created and supply you with API keys. In the meantime,{" "}
            <Link href="https://docs.stately.cloud">read our documentation</Link>.
          </Text>
        </Flex>
      </Card>
    </Box>
  );
}
