import { useAuth0 } from "@auth0/auth0-react";
import { Code } from "@connectrpc/connect";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router";
import { StatelyError } from "services/client/errors";
import { useWhoamiError } from "state/user/hooks";
import styles from "./App.m.css";
import ErrorBoundary from "./errors/ErrorBoundary";
import ErrorPanel from "./errors/ErrorPanel";
import AuthenticatedHome from "./home/AuthenticatedHome";
import LoadingHome from "./home/LoadingHome";
import UnauthenticatedHome from "./home/UnauthenticatedHome";
import LogoutButton from "./menu/LogoutButton";
import NotFound from "./not-found/NotFound";
import { LoadingOverlay } from "./shared/LoadingOverlay";
import Shell from "./shell/Shell";
import StorePage from "./store/StorePage";
import SchemaViewer from "./store/schema-viewer/SchemaViewer";
import SupportPage from "./support/SupportPage";
import UserAccount from "./user-account/UserAccount";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const LazyAdminPage = lazy(() => import(/* webpackChunkName: "admin" */ "./admin/AdminPage"));

/**
 * The App component is the root of our application where all the providers and routing and other stuff gets set up.
 */
export default function App() {
  const { isAuthenticated, isLoading, error } = useAuth0();
  const whoamiError = useWhoamiError();
  const isTestUserPresent = localStorage.getItem("testUserAuthToken") !== null;

  if (error) {
    <div className={styles.error}>
      <ErrorPanel message="Auth error" error={error} />
    </div>;
  }

  if (whoamiError) {
    // TODO: Helper function to check if error is fatal
    if (whoamiError instanceof StatelyError && whoamiError.code === Code.Unauthenticated) {
      return (
        <div className={styles.app}>
          <UnauthenticatedHome />
        </div>
      );
    }
    return (
      <div className={styles.error}>
        <ErrorPanel message="Error getting user details" error={whoamiError} />
        <LogoutButton />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.app}>
        <LoadingHome />
      </div>
    );
  }

  if (!isAuthenticated && !isTestUserPresent) {
    return (
      <div className={styles.app}>
        <UnauthenticatedHome />
      </div>
    );
  }

  return (
    <div className={styles.app}>
      <ErrorBoundary name="Console Code">
        <Suspense fallback={<LoadingOverlay>Loading code...</LoadingOverlay>}>
          <SentryRoutes>
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingOverlay>Loading user info...</LoadingOverlay>}>
                  <Shell />
                </Suspense>
              }
            >
              <Route
                path="/store/:storeId"
                element={
                  <ErrorBoundary name="Store">
                    <StorePage />
                  </ErrorBoundary>
                }
              />
              {/* <Route
                path="/store/:storeId/browse"
                element={
                  <ErrorBoundary name="DataBrowser">
                    <DataBrowser />
                  </ErrorBoundary>
                }
              /> */}
              <Route
                path="/store/:storeId/schema"
                element={
                  <ErrorBoundary name="SchemaViewer">
                    <SchemaViewer />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/account"
                element={
                  <ErrorBoundary name="UserAccount">
                    <UserAccount />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/support"
                element={
                  <ErrorBoundary name="Support">
                    <SupportPage />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin"
                element={
                  <Suspense fallback={<LoadingOverlay>Loading admin...</LoadingOverlay>}>
                    <LazyAdminPage />
                  </Suspense>
                }
              />
              <Route path="/loading" element={<LoadingOverlay>Loading...</LoadingOverlay>} />
              <Route
                path="/"
                element={
                  <ErrorBoundary name="Home">
                    <AuthenticatedHome />
                  </ErrorBoundary>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Route>
            {/* these routes are mostly a convenience for previewing while developing */}
            <Route path="/login" element={<UnauthenticatedHome />} />
            <Route path="/loading" element={<LoadingHome />} />
          </SentryRoutes>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
