// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/store_info.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_dbmanagement_config_group } from "./config_group_pb.js";
import { file_dbmanagement_config_item } from "./config_item_pb.js";
import { file_dbmanagement_config_store } from "./config_store_pb.js";
import { file_dbmanagement_region } from "./region_pb.js";

/**
 * Describes the file dbmanagement/store_info.proto.
 */
export const file_dbmanagement_store_info =
  /*@__PURE__*/
  fileDesc(
    "Ch1kYm1hbmFnZW1lbnQvc3RvcmVfaW5mby5wcm90bxIUc3RhdGVseS5kYm1hbmFnZW1lbnQiiQEKCVN0b3JlSW5mbxIQCghzdG9yZV9pZBgBIAEoBBIMCgRuYW1lGAIgASgJEhEKCXNjaGVtYV9pZBgFIAEoBBITCgtkZXNjcmlwdGlvbhgDIAEoCRI0Cg5kZWZhdWx0X3JlZ2lvbhgEIAEoDjIcLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlJlZ2lvbiKHBAoLU3RvcmVDb25maWcSEAoIc3RvcmVfaWQYASABKAQSEQoJc2NoZW1hX2lkGAwgASgEEgwKBG5hbWUYAiABKAkSEwoLZGVzY3JpcHRpb24YAyABKAkSEgoKcHJvamVjdF9pZBgEIAEoBBIXCg9vcmdhbml6YXRpb25faWQYBSABKAQSMwoHYmFja3VwcxgGIAEoCzIiLnN0YXRlbHkuZGJtYW5hZ2VtZW50LkJhY2t1cENvbmZpZxJIChVyZXF1ZXN0X3JhdGVfbGltaXRpbmcYByABKAsyKS5zdGF0ZWx5LmRibWFuYWdlbWVudC5SZXF1ZXN0UmF0ZUxpbWl0aW5nEkgKFWN1c3RvbV90YWJsZV9hY2Nlc3NvchgIIAEoCzIpLnN0YXRlbHkuZGJtYW5hZ2VtZW50LkN1c3RvbVRhYmxlQWNjZXNzb3ISOAoNZ3JvdXBfY29uZmlncxgJIAMoCzIhLnN0YXRlbHkuZGJtYW5hZ2VtZW50Lkdyb3VwQ29uZmlnEjoKDGl0ZW1fY29uZmlncxgKIAMoCzIkLnN0YXRlbHkuZGJtYW5hZ2VtZW50Lkl0ZW1UeXBlQ29uZmlnEkQKD3JlZ2lvbmFsX2NvbmZpZxgLIAEoCzIrLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlJlZ2lvbmFsQ29uZmlndXJhdGlvbiJTChVSZWdpb25hbENvbmZpZ3VyYXRpb24SOgoOcmVnaW9uX2NvbmZpZ3MYASADKAsyIi5zdGF0ZWx5LmRibWFuYWdlbWVudC5SZWdpb25Db25maWciPAoMUmVnaW9uQ29uZmlnEiwKBnJlZ2lvbhgBIAEoDjIcLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlJlZ2lvbkKbAQoYY29tLnN0YXRlbHkuZGJtYW5hZ2VtZW50Qg5TdG9yZUluZm9Qcm90b1ABogIDU0RYqgIUU3RhdGVseS5EYm1hbmFnZW1lbnTKAhRTdGF0ZWx5XERibWFuYWdlbWVudOICIFN0YXRlbHlcRGJtYW5hZ2VtZW50XEdQQk1ldGFkYXRh6gIVU3RhdGVseTo6RGJtYW5hZ2VtZW50YgZwcm90bzM",
    [
      file_dbmanagement_config_group,
      file_dbmanagement_config_item,
      file_dbmanagement_config_store,
      file_dbmanagement_region,
    ],
  );

/**
 * Describes the message stately.dbmanagement.StoreInfo.
 * Use `create(StoreInfoSchema)` to create a new message.
 */
export const StoreInfoSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_store_info, 0);

/**
 * Describes the message stately.dbmanagement.StoreConfig.
 * Use `create(StoreConfigSchema)` to create a new message.
 */
export const StoreConfigSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_store_info, 1);

/**
 * Describes the message stately.dbmanagement.RegionalConfiguration.
 * Use `create(RegionalConfigurationSchema)` to create a new message.
 */
export const RegionalConfigurationSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_store_info, 2);

/**
 * Describes the message stately.dbmanagement.RegionConfig.
 * Use `create(RegionConfigSchema)` to create a new message.
 */
export const RegionConfigSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_store_info, 3);
