import { AppState, Auth0Provider, AuthorizationParams } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const authParams: AuthorizationParams = {
  redirect_uri: window.location.origin,
  audience: $AUTH0_AUDIENCE,
};

export function Auth0ProviderWithNavigate({ children }: React.PropsWithChildren) {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      navigate(appState?.returnTo || window.location.pathname);
    },
    [navigate],
  );

  if (!($AUTH0_DOMAIN && $AUTH0_CLIENT_ID)) {
    throw new Error("Auth0 environment variables not set.");
  }

  // TODO: How much of this do we need?

  return (
    <Auth0Provider
      domain={$AUTH0_DOMAIN}
      clientId={$AUTH0_CLIENT_ID}
      authorizationParams={authParams}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}
