// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/create_store.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file dbmanagement/create_store.proto.
 */
export const file_dbmanagement_create_store =
  /*@__PURE__*/
  fileDesc(
    "Ch9kYm1hbmFnZW1lbnQvY3JlYXRlX3N0b3JlLnByb3RvEhRzdGF0ZWx5LmRibWFuYWdlbWVudCJLChJDcmVhdGVTdG9yZVJlcXVlc3QSEgoKcHJvamVjdF9pZBgBIAEoBBIMCgRuYW1lGAIgASgJEhMKC2Rlc2NyaXB0aW9uGAMgASgJIicKE0NyZWF0ZVN0b3JlUmVzcG9uc2USEAoIc3RvcmVfaWQYASABKARCnQEKGGNvbS5zdGF0ZWx5LmRibWFuYWdlbWVudEIQQ3JlYXRlU3RvcmVQcm90b1ABogIDU0RYqgIUU3RhdGVseS5EYm1hbmFnZW1lbnTKAhRTdGF0ZWx5XERibWFuYWdlbWVudOICIFN0YXRlbHlcRGJtYW5hZ2VtZW50XEdQQk1ldGFkYXRh6gIVU3RhdGVseTo6RGJtYW5hZ2VtZW50YgZwcm90bzM",
  );

/**
 * Describes the message stately.dbmanagement.CreateStoreRequest.
 * Use `create(CreateStoreRequestSchema)` to create a new message.
 */
export const CreateStoreRequestSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_create_store, 0);

/**
 * Describes the message stately.dbmanagement.CreateStoreResponse.
 * Use `create(CreateStoreResponseSchema)` to create a new message.
 */
export const CreateStoreResponseSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_create_store, 1);
